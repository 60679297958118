import Auth from "@/components/core/Auth"
import Toaster from "@/components/shared/Toaster"
import "@/css/index.css"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { NextPage } from "next"
import { appWithTranslation } from "next-i18next"
import { AppProps } from "next/app"
import { ReactElement, ReactNode, useState } from "react"
import "../../firebase"

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout || ((page) => page)
  const Layout = getLayout(<Component {...pageProps} />)

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
  )

  return (
    <div className="font-grotesk">
      <QueryClientProvider client={queryClient}>
        <>
          <Toaster />
          <Auth>{Layout}</Auth>
        </>
      </QueryClientProvider>
    </div>
  )
}

export default appWithTranslation(App)
